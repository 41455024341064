import React from 'react'
import './Heading.css'

export default function Heading() {
  return (
  
    <div className='ueheading'>
      Upcoming Events
      <div className='ue-heading-underline'></div>
    </div>
  
  )
}
