import React from "react";
import "./CulturalAbout.css";
function About() {
  return (
    <div className="about-content">
      <div className="box">
        <div id="AboutTitleCultural" className="about-title">
          About the Club
        </div>
        <div className="about-text">
          <p>
            The Cultural and Dance Club aims at moulding the personality of
            students and exposes them to a wide range of cultural events
            throughout the year. The Club also aims at developing the creativity
            of the students by providing and managing a multitude of themes and
            decorations throughout the campus during the fests. The club also
            manages all the independent dance societies in the college.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
