import React from 'react';
import './SportsAbout.css';
function About () {
    return (
    <div className="about-content">
        
        <div className="box">
            <div id='AboutSports' className="about-title">
            About the Club
        </div>
   <div className="about-text">
    <p>Lorem ipsum dolor sit amet, consectetur 
        adipiscing elit, sed do eiusmod tempor 
        incididunt ut labore et dolore magna 
        aliqua. Ut enim ad minim veniam, quis
         nostrud exercitation ullamco laboris 
         nisi ut aliquip ex ea commodo consequat. 
         Duis aute irure dolor in reprehenderit 
         in voluptate velit esse cillum dolore eu
          fugiat nulla pariatur. Excepteur sint 
          occaecat cupidatat non proident, sunt 
          in culpa qui officia deserunt mollit 
          anim id est laborum.
    </p>
    </div>
    </div>
    </div>
    

    );
}

export default About;
