import React from 'react';
import './About.css';
function About () {
    return (
    <div className="about-content">
        
        <div className="box">
            <div id='AboutTMP' className="about-title">
            About the Club
        </div>
   <div className="about-text">
    <p>
        CLUB, with a composition of Movies, Photography and 'Createch : The technical Club of JUIT.
        This Club comes with a complete package of promoting overall growth, leadership, innovation, and creativity in all forms. With every passing day, it is creating more learning opportunities for students in creative field including videography, photography, coding, design-softwares like photoshop, etc.
        From dealing with all events photography to organizing boot camps, is another crucial part of this club.
        Including all features, it comes with an add-on perk of telecasting cricket matches and movies for students.
    </p>
    </div>
    </div>
    </div>
    

    );
}

export default About;
